import styled from '@emotion/styled'

export const SoloDescription = styled.span`
  margin-left: 1rem;
`

export const PublicationDescription = styled.span`
  margin-left: 0;
`

export const SubHeader = styled.h2`
  font-size: 1.2rem;
  font-weight: 400;
  /* line-height: 40px;
  font-style: normal;
  margin-bottom: 0.9rem; */
  color: #121212;
  /* letter-spacing: 0.05rem;
  margin-top: 1em; */
  text-transform: uppercase;
  padding: 1rem 0;
`

export const AboutSection = styled.section`
  padding-top: 2rem;
  li {
    display: flex;
    flex-direction: row;
  }
`

export const CVSection = styled.section`
  margin-top: 2rem;
  margin-bottom: 2rem;
`

export const EntryYear = styled.span`
  padding-right: 1rem;
`
