import * as React from 'react'
import type { ReactNode } from 'react'

import styled from '@emotion/styled'

const Yellow = styled.a`
  color: #d8a94a;
`

export const ExternalYellow = ({
  linkTo,
  children,
}: {
  linkTo: string
  children?: ReactNode
}): JSX.Element => (
  <Yellow href={linkTo} target="_blank" rel="noreferrer">
    {children}
  </Yellow>
)
